import React from 'react'
import './landingPage.css'
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../Navbar/navbar'


export const HeroSection = () => {
    const navigate = useNavigate();
    const scrollToDiv=(e, divId)=>{
        e.preventDefault();
        const element=document.getElementById(divId);
        if(element){
            element.scrollIntoView({behavior:'smooth'});
        }
    }
    return (
        <div className='heroSectionContainer'>
            <Navbar />
            <h1 className='pixel-effect'>THE ULTIMATE DESTINATION FOR FOOTBALL ENTHUSIASTS</h1>
            <div className='buttonContainer'>
                <button className='primaryButton' onClick={(e)=>{scrollToDiv(e,"tournamentsSliderContainer")}}>Tournaments</button>
                <button className='secondaryButton' onClick={() => { navigate('/fixtures') }}>Fixtures</button>
            </div>
        </div>
    )

}

