import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Footer } from '../../components/Footer/footer'
import { Navbar } from '../../components/Navbar/navbar'
import { useAuthContext } from '../../context/authContext'

export const RegistrationConfirmation = () => {

    const {setTeamName}=useAuthContext();
    const [loading, setLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const { registration_id } = useParams();
    let data = registration_id.split(' ');
    const isMobileScreen = window.innerWidth <= 1000 ? true : false

    useEffect(() => {
        window.scrollTo(0, 0);
        setTeamName("");
        const isConfirmationValid = async () => {
            let body = {
                order_id: data[0],
                transaction_id: data[1]
            }
            const res = await fetch(process.env.REACT_APP_ENV_URL + '/api/user/confirmTeamRegistration', {
                method: 'POST',
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(body),
                credentials: 'include'
            })
            if (res.status === 200) {
                setLoading(false);
                setIsValid(true);
            } else {
                setLoading(false);
                setIsValid(false);
            }
        }
        isConfirmationValid();
    })
    return (
        <div style={{ width: '100%', minHeight: '100vh', height: 'fit-Content', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
            <Navbar />
            <div style={{ width: '100%', minHeight: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {
                    loading ? <>Loading</>
                        :
                        isValid ?
                            <>
                                <h1 style={{ marginBottom: isMobileScreen ? 30 : 50, fontSize: isMobileScreen ? '28px' : '' }}>{data[2]} registered successfully!</h1>
                                <div className='info_wrapper' style={{ width: isMobileScreen ? '95%' : '35%', minHeight: '20vh', display:'flex', flexDirection:'column', alignItems:'center' }}>
                                    <div style={{width:'90%'}}>
                                        <p>Registration Id</p>
                                        <p>{data[0]}</p>
                                    </div>
                                    <div style={{width:'90%'}}>
                                        <p>Transaction Id</p>
                                        <p>{data[1]}</p>
                                    </div>
                                    <p style={{ marginTop: '2rem', fontSize: '1rem', fontFamily: 'CustomFontLight' , width:isMobileScreen?'90%':'100%'}}>Please take a screenshot of this for future reference</p>
                                </div>
                            </>
                            :
                            <p>Not validated</p>
                }
            </div>
            <Footer />
        </div>
    )
}
