import React, { useEffect, useState } from 'react'
import { IoAddCircle, IoClose } from "react-icons/io5";
import { useAuthContext } from '../context/authContext';
import toast from 'react-hot-toast';

export const AddPlayers = () => {
    
    const [playerName, setPlayerName] = useState("");
    const { players, setPlayers } = useAuthContext();
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    const addPlayer = () => {

        if (playerName.length == 0) {
            toast.error('Please enter a player name');
            return;
        }
        if (players.length == 8) {
            toast.error('A team cannot have more than 8 players'); // to be replaced with notifications
            return;
        }
        if (playerName.length <= 2) {
            toast.error('Player name too short');
            return;
        }
        let name = playerName.trim();
        let regex=/^\S*$/;
        if(!regex.test(name)){
            toast.error('Only one word for a player');
            return;
        }
        regex = /^[A-Za-z]+$/;
        if (!regex.test(name)) {
            toast.error('Not a valid player name');
            return;
        }
        //name should have only characters and nothing else

        setPlayers([...players, name]);
        setPlayerName("");
        return;
    }

    const removePlayer = (indexToRemove) => {
        setPlayers((players) =>
            players.filter((_, index) => index !== indexToRemove)
        );
    }

    return (
        <div className='form_add_players'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='input_wrapper'>
                    <p>Player Name</p>
                    <input type="text" placeholder='' value={playerName} onChange={(e) => { setPlayerName(e.target.value) }} />
                </div>
                <button style={{ border: 'none', borderRadius: '50%', transform: 'translateY(-5px)', marginLeft: '0.5rem', padding: 0, backgroundColor: 'transparent' }} onClick={addPlayer}>
                    <IoAddCircle fill='rgba(255,255,255,0.2)' size={38} style={{ cursor: 'pointer' }} className='add_icon'
                    />
                </button>

            </div>
            <div className='players_list_area'>
                {players.map((item, index) => {
                    return (
                        <div key={index} className='player_name_wrapper'>
                            <p style={{ backgroundColor: 'transparent' }}>{item}</p>
                            <IoClose style={{ backgroundColor: 'transparent', marginLeft: '0.5rem' }} onClick={() => { removePlayer(index) }} />
                        </div>

                    )
                })}
            </div>

        </div>
    )
}
