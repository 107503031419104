import React from 'react'
import { useAuthContext } from '../context/authContext';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const AssignManager = () => {
    const { managerFirstName, setManagerFirstName,
        // managerLastName, setManagerLastName,
        // managerEmail, setManagerEmail,
        managerContactNumber, setManagerContactNumber,
        players
    } = useAuthContext();

    const isMobileScreen = window.innerWidth <= 1000 ? true : false;
    const handlePhoneChange = (value, countryData) => {
        const rawPhoneNumber = value.replace(`${countryData.dialCode}`, '');
        setManagerContactNumber(`+${countryData.dialCode}-${rawPhoneNumber}`);
    }
    return (
        <div className='form'>
            <select
                style={{
                    width: isMobileScreen ? '100%' : '100%',
                    color: '#fff',
                    backgroundColor: '#71797e32',
                    height: '56px',
                    border: 'none',
                    borderRadius: '0.5rem',
                    paddingLeft: '1rem',
                    fontSize: '1rem'
                }}
                onChange={(e) => { setManagerFirstName(e.target.value) }}
            >
                <option value={null}>{managerFirstName.length === 0 ? 'Assign Captain' : managerFirstName}</option>
                {players.map((name, index) => (
                    <option value={name} key={index}>{name}</option>
                ))}
            </select>
            {/* <div className='input_wrapper'>
                <p>Email Address</p>
                <input type="email" placeholder='' value={managerEmail} onChange={(e) => { setManagerEmail(e.target.value) }} />
            </div> */}
            <div className='input_wrapper' style={{width:'100%'}}>
                <p>Contact Number</p>
                <PhoneInput placeholder='' value={managerContactNumber} onChange={handlePhoneChange} dropdownStyle={{ background: '#0b0b0b', color: 'crimson' }} buttonStyle={{ background: "transparent", border: 'none' }} country={'in'} />
            </div>
        </div>
    )
}
