import { React, useEffect, useState } from 'react'
import './landingPage.css'
import { Slider } from './Slider/slider';
import { OneTimeBanner } from './oneTimeBanner';

export const TournamentsSlider = () => {
    const [responded, setResponded] = useState(false);  
    return (
        <div className='tournamentsSliderContainer' id="tournamentsSliderContainer">
            {
                // <OneTimeBanner data={setResponded}/>
                <Slider/>
            }
        </div>
    )
}
