import { createContext, useContext, useState } from 'react';

export const AuthContext = createContext();

export const useAuthContext = () => {
    return useContext(AuthContext);
}

export const AuthContextProvider = ({ children }) => {
    const [teamName, setTeamName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [divisionId, setDivisionId] = useState(null);
    const [venueId, setVenueId] = useState(null);
    const [players, setPlayers] = useState([]);
    const [managerFirstName, setManagerFirstName] = useState("");
    const [managerLastName, setManagerLastName] = useState("null");
    const [managerEmail, setManagerEmail] = useState("");
    const [managerContactNumber, setManagerContactNumber] = useState("");
    const [tournamentId, setTournamentId] = useState(null);
    const [currentAdminMenu, setCurrentAdminMenu]=useState(null);

    return <AuthContext.Provider value={{
        teamName, setTeamName,
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        contactNumber, setContactNumber,
        password, setPassword,
        confirmPassword, setConfirmPassword,
        divisionId, setDivisionId,
        venueId, setVenueId,
        players, setPlayers,
        managerFirstName, setManagerFirstName,
        managerLastName, setManagerLastName,
        managerEmail, setManagerEmail,
        managerContactNumber, setManagerContactNumber,
        tournamentId, setTournamentId,
        currentAdminMenu, setCurrentAdminMenu
    }}>
        {children}
    </AuthContext.Provider>
}