import React, { useState } from 'react'
import './slider.css';
import { useNavigate } from 'react-router-dom';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import toast from 'react-hot-toast';
import { ClipLoader } from 'react-spinners';



export const Slider = () => {

    const [loading, setLoading] = useState(false);
    const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay({
        stopOnInteraction: false
    })]);
    const navigate = useNavigate();
    const tournamentData = [
        {
            name: 'Doon Premier Cup',
            date: '02/08/2025',
            image: 'https://images.pexels.com/photos/1302514/pexels-photo-1302514.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        },
    ]
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        })
    }

    const handleRegister = async () => {
        setLoading(true);
        const res = await fetch(process.env.REACT_APP_ENV_URL + '/api/user/getTeamsCount', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        const data = await res.json();
        if (res.status === 200) {
            console.log(data.teams.count);
            if (data.teams.count === "64") {
                toast.error("Registrations Full");
                setLoading(false);
            } else {
                setLoading(false);
                navigate('/registerTeam');
            }
        }
        else {
            setLoading(false);
            toast.error("Something went wrong...please try again");
        }
    }

    return (
        <div className='sliderContainer'>
            <div className='embla' ref={emblaRef}>
                <div className='embla__container'>
                    {tournamentData.map((item, index) => (
                        <div className='embla__slide' key={index} style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                            <h1>{item.name}</h1>
                            <div>
                                <h3>Starts</h3>
                                <h3>{formatDate(item.date)}</h3>
                            </div>
                            <button className='primaryButton' onClick={handleRegister}>
                                {
                                    loading ?
                                        <ClipLoader color='#fff' size={18} speedMultiplier={0.7} />
                                        :
                                        'Register'
                                }
                            </button>
                            <p style={{ fontFamily: 'CustomFontLight', marginTop: '3rem', cursor: 'pointer', color: '#0096FF' }} onClick={() => { navigate('/about-cup') }}>About the cup</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
