import React, { useState, useEffect } from 'react'
import { Navbar } from '../../components/Navbar/navbar';
import { Footer } from '../../components/Footer/footer';
import { useNavigate } from 'react-router-dom';
import { AddPlayers } from '../../components/addPlayers';
import { AssignManager } from '../../components/assignManager';
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useAuthContext } from '../../context/authContext';
import toast from 'react-hot-toast';
import { teamNameAlreadyTaken, userEmailRegistered, userContactRegistered, managerEmailAlreadyRegistered, managerContactAlreadyRegistered } from '../../utils/helperFunctions';

//custom contact number input
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './registerTeam.css'

//loader component import from react spinners
import { ClipLoader } from 'react-spinners';

export const RegisterTeam = () => {

    const {
        tournamentId, divisionId,
        setTournamentId, setDivisionId,
        venueId, setVenueId,
        teamName, setTeamName,
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        contactNumber,
        setContactNumber,
        password, setPassword,
        confirmPassword, setConfirmPassword,
        managerEmail, managerContactNumber,
        managerFirstName, setManagerFirstName,
        players

    } = useAuthContext(); //to be converted to int before sending to backend

    const isMobileScreen = window.innerWidth <= 1000 ? true : false;
    const [loading, setLoading] = useState(false);
    const [tournaments, setTournaments] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [venues, setVenues] = useState([]);
    const [tournamentIndex, setTournamentIndex] = useState(-1);

    useEffect(() => {
        window.scrollTo(0, 0);
        //fetching tournaments
        const getTeamsCount = async () => {
            const res = await fetch(process.env.REACT_APP_ENV_URL + '/api/user/getTeamsCount', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            const data = await res.json();
            if (res.status === 200) {
                console.log(data.teams.count);
                if (data.teams.count === "64") {
                    toast.error("Registrations full for Doon Premier Cup");
                    setTimeout(()=>{
                        navigate('/');
                    },2000)
                }
                else{
                    fetchTournaments();
                }
            }
            else {
                toast.error("Something went wrong...please try again");
                setTimeout(() => {
                    navigate('/');
                }, 2000)
            }
        }

        const fetchTournaments = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/admin/gettournaments', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include'
                });
                const res = await response.json();
                let data = res.data;
                data = await data.map((item) => ({
                    value: item.tournament_id,
                    label: item.name,
                    divisions: item.divisions,
                    end_date: item.end_date,
                    start_date: item.start_date,
                    venues: item.venues
                }));
                setTournaments(data);
            } catch (error) {
                console.log('Error fetching tournaments ', error.message);
                return [];
            }
        }

        getTeamsCount();
       
    }, [])

    useEffect(() => {
        //shift the below functions to utils folder

        const fetchDivisions = async () => {
            const params = new URLSearchParams();
            params.append("ids", tournaments[tournamentIndex].divisions.join(","));
            const response = await fetch(process.env.REACT_APP_ENV_URL + `/api/user/getdivisions?${params.toString()}`, {
                method: 'GET',
                header: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            const res = await response.json();
            let data = res.data;
            data = await data.map((item) => ({
                value: item.division_id,
                label: item.name
            }))
            console.log(data);
            setDivisions(data);
        }

        const fetchVenues = async () => {
            const params = new URLSearchParams();
            params.append("ids", tournaments[tournamentIndex].venues.join(","));
            const response = await fetch(process.env.REACT_APP_ENV_URL + `/api/user/getVenues?${params.toString()}`, {
                method: 'GET',
                header: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            const res = await response.json();
            let data = res.data;
            data = await data.map((item) => ({
                value: item.venue_id,
                label: `${item.name} ${item.address}`
            }))
            console.log(data);
            setVenues(data);
        }

        tournamentIndex != -1 && fetchDivisions() && fetchVenues();

    }, [tournamentIndex])

    const [addPlayers, setAddPlayers] = useState(false);
    const [assignManager, setAssignManager] = useState(false);
    const navigate = useNavigate();

    const setAssignManagerToTrue = () => {
        if (players.length < 5) {
            toast.error('A team should have atleast 5 players'); // to be replaced with notification
            return;
        }
        setAssignManager(true);
    }
    const handleGoOneStepBack = () => {
        if (addPlayers && !assignManager) {
            setAddPlayers(false);
        }
        else if (assignManager) {
            setManagerFirstName("");
            setAssignManager(false);
        }
    }
    const setAddPlayersToTrue = () => {
        if (password !== confirmPassword) {
            toast.error("Passwords don't match");
            return;
        }
        checkForNullValues();
    }
    const navigateToReview = async () => {
        // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        // if (managerEmail.length === 0 || !emailRegex.test(managerEmail)) {
        //     toast.error('Not a valid email Id');
        //     return;
        // }
        setLoading(true);
        if (managerFirstName.length === 0) {
            toast.error("Please select a captain");
            setLoading(false);
            return;
        }
        if (managerContactNumber.length != 14) {
            toast.error('Not a valid contact number');
            setLoading(false);
            return 0;
        }
        // setLoading(true);
        // if (await managerEmailAlreadyRegistered(managerEmail)) {
        //     toast.error("Email already in use");
        //     setLoading(false);
        //     return true;
        // }
        if (await managerContactAlreadyRegistered(managerContactNumber)) {
            toast.error("Contact number already in use");
            setLoading(false);
            return true;
        }
        setLoading(false);
        navigate('/registerteam/review');
    }
    //checking for null values
    const checkForNullValues = async () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (tournamentId === null) {
            toast.error("Please select a tournament");
            return true;
        }
        if (divisionId === null) {
            toast.error("Please select a division");
            return true;
        }
        if (venueId === null) {
            toast.error("Please select a venue");
            return true;
        }
        setLoading(true);
        if (teamName.length === 0 || await teamNameAlreadyTaken(teamName)) {
            toast.error(teamName.length == 0 ? "Please enter the team name" : "Team name already taken");
            setLoading(false);
            return true;
        }
        setLoading(false);
        if (firstName.length === 0) {
            toast.error("Please enter your name");
            return true;
        }
        if (email.length === 0 || !emailRegex.test(email)) {
            toast.error("Not a valid email Id");
            return true;
        }
        setLoading(true);
        if (await userEmailRegistered(email)) {
            toast.error("Email already in use");
            setLoading(false);
            return true;
        }
        setLoading(false);
        if (contactNumber.length != 14) {
            toast.error("Not a valid contact number");
            return true;
        }
        setLoading(true);
        if (await userContactRegistered(contactNumber)) {
            toast.error("Contact number already in use");
            setLoading(false);
            return true;
        }
        setLoading(false);
        if (password.length < 5) {
            toast.error("Please provide a strong password");
            return true;
        }
        setAddPlayers(true);
        return;
    }
    //setting phone number 
    const handlePhoneChange = (value, countryData) => {
        const rawPhoneNumber = value.replace(`${countryData.dialCode}`, '');
        setContactNumber(`+${countryData.dialCode}-${rawPhoneNumber}`);
    }
    const handleChangeInDivision = (selectedValue) => {
        setDivisionId(divisions[selectedValue]);
    }
    const handleChangeInVenueId = (selectedValue) => {
        setVenueId(venues[selectedValue]);
    }
    const handleTournamentChange = (index) => {
        setTournamentId(tournaments[index]);
        setTournamentIndex(index);
    }

    return (
        <div style={{ width: '100%', minHeight: '100vh', height: 'fit-Content', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
            <Navbar />
            {addPlayers || assignManager
                ?
                <div className='back_pressable' onClick={handleGoOneStepBack}>
                    <IoIosArrowRoundBack fill='rgba(255,255,255,0.4)' />
                    <h4 style={{ color: 'rgba(255,255,255,0.4)', marginLeft: '0.2rem' }}>
                        {(addPlayers && !assignManager) ? 'Edit Information' : 'Edit Players'}
                    </h4>
                </div>
                : <></>
            }
            <div className='form_container'>
                {
                    !addPlayers ?
                        <div className='form'>
                            <select
                                style={{
                                    width: isMobileScreen ? '100%' : '48%',
                                    color: '#fff',
                                    backgroundColor: '#71797e32',
                                    height: '56px',
                                    border: 'none',
                                    borderRadius: '0.5rem',
                                    paddingLeft: '1rem',
                                    fontSize: '1rem'
                                }}
                                onChange={(e) => { handleTournamentChange(e.target.value) }}
                            >
                                <option value={null}>{tournamentId == null ? 'Select Tournament' : tournamentId.label}</option>
                                {tournaments.map((tournament, index) => (
                                    <option value={index} key={tournament.value}>{tournament.label}</option>
                                ))}
                            </select>
                            <select
                                style={{
                                    width: isMobileScreen ? '100%' : '48%',
                                    color: '#fff',
                                    backgroundColor: '#71797e32',
                                    height: '56px',
                                    border: 'none',
                                    borderRadius: '0.5rem',
                                    paddingLeft: '1rem',
                                    fontSize: '1rem'
                                }}
                                onChange={(e) => { handleChangeInDivision(e.target.value) }}
                            >
                                <option value={null}>{divisionId == null ? 'Select Division' : divisionId.label}</option>
                                {divisions.map((division, index) => (
                                    <option value={index} key={index}>{division.label}</option>
                                ))}
                            </select>
                            <select
                                style={{
                                    width: isMobileScreen ? '100%' : '48%',
                                    color: '#fff',
                                    backgroundColor: '#71797e32',
                                    height: '56px',
                                    border: 'none',
                                    borderRadius: '0.5rem',
                                    paddingLeft: '1rem',
                                    fontSize: '1rem'
                                }}
                                onChange={(e) => { handleChangeInVenueId(e.target.value) }}
                            >
                                <option value={null}>{venueId == null ? 'Select Venue' : venueId.label}</option>
                                {venues.map((venue, index) => (
                                    <option value={index} key={index}>{venue.label}</option>
                                ))}
                            </select>

                            <div className='input_wrapper'>
                                <p>Team Name</p>
                                <input type="text" placeholder='' value={teamName} onChange={(e) => { setTeamName(e.target.value) }} />
                            </div>
                            <div className='input_wrapper'>
                                <p>First Name</p>
                                <input type="text" placeholder='' value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                            </div>
                            <div className='input_wrapper'>
                                <p>Last Name</p>
                                <input type="text" placeholder='' value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                            </div>
                            <div className='input_wrapper'>
                                <p>Email Address</p>
                                <input type="email" placeholder='' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                            <div className='input_wrapper'>
                                <p>Contact Number</p>
                                <PhoneInput placeholder='' value={contactNumber} onChange={handlePhoneChange} dropdownStyle={{ background: '#0b0b0b', color: 'crimson' }} buttonStyle={{ background: "transparent", border: 'none' }} country={'in'}
                                />
                            </div>
                            <div className='input_wrapper'>
                                <p>Password</p>
                                <input type="password" placeholder='' value={password} onChange={(e) => { setPassword(e.target.value) }} />
                            </div>
                            <div className='input_wrapper'>
                                <p>Confirm Password</p>
                                <input type="password" placeholder='' value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                            </div>
                        </div>
                        :
                        !assignManager ?
                            <AddPlayers />
                            :
                            <AssignManager />
                }
                {
                    !addPlayers ?
                        <button className='primaryButton editButton' onClick={setAddPlayersToTrue}>
                            {!loading ?
                                <div style={{ width: '100%', height: '100%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    Add Players
                                    <IoIosArrowRoundForward size={22} style={{ backgroundColor: 'transparent', marginLeft: '0.2rem' }} />
                                </div>
                                :
                                <ClipLoader color='#fff' size={18} speedMultiplier={0.7} />
                            }

                        </button>
                        :
                        !assignManager ?
                            <button className='primaryButton editButton' onClick={setAssignManagerToTrue}>
                                {
                                    !loading ?
                                        <div style={{ width: '100%', height: '100%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                            Assign Captain
                                            <IoIosArrowRoundForward size={22} style={{ backgroundColor: 'transparent', marginLeft: '0.2rem' }} />
                                        </div>
                                        :
                                        <ClipLoader color='#fff' size={18} speedMultiplier={0.7} />
                                }
                            </button>
                            :
                            <button className='primaryButton editButton' onClick={navigateToReview}>
                                {
                                    !loading ?
                                        <div style={{ width: '100%', height: '100%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                            Review
                                            <IoIosArrowRoundForward size={22} style={{ backgroundColor: 'transparent', marginLeft: '0.2rem' }} />
                                        </div>
                                        :
                                        <ClipLoader color='#fff' size={18} speedMultiplier={0.7} />
                                }
                            </button>
                }
            </div>
            <Footer />
        </div>
    )
}
