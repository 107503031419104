export const teamNameAlreadyTaken = async (teamName) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_ENV_URL + `/api/user/getteamname/${teamName}`, {
            method: "GET",
            headers: { "Content-type": "application/json" },
            credentials: 'include',
        }).then((response) => {
            //response status code of 200 means that team name is not taken
            if (response.status == 200) {
                resolve(false)
            } else {
                resolve(true)
            }
        }).catch((error) => {
            reject(error);
        })
    })


}
export const userEmailRegistered = async (email) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_ENV_URL + `/api/user/getUserEmail/${email}`, {
            method: "GET",
            headers: { "Content-type": "application/json" },
            credentials: 'include',
        }).then((response) => {
            if (response.status === 200) {
                resolve(false);
            } else {
                resolve(true);
            }
        }).catch((error) => {
            reject(error);
        })
    })
}
export const userContactRegistered = async (contact) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_ENV_URL + `/api/user/getUserContact/${contact}`, {
            method: "GET",
            headers: { "Content-type": "application/json" },
            credentials: 'include',
        }).then(async (response) => {
            if (response.status === 200) {
                resolve(false);
            } else {
                resolve(true);
            }
        }).catch((error) => {
            reject(error);
        })
    })
}
export const managerEmailAlreadyRegistered = async (email) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_ENV_URL + `/api/user/getManagerEmail/${email}`, {
            method: "GET",
            headers: { "Content-type": "application/json" },
            credentials: 'include',
        }).then((response) => {
            if (response.status === 200) {
                resolve(false);
            } else {
                resolve(true);
            }
        }).catch((error) => {
            reject(error);
        })
    })
}
export const managerContactAlreadyRegistered = async (contact) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_ENV_URL + `/api/user/getManagerContact/${contact}`, {
            method: "GET",
            headers: { "Content-type": "application/json" },
            credentials: 'include',
        }).then((response) => {
            if (response.status === 200) {
                resolve(false);
            } else {
                resolve(true);
            }
        }).catch((error) => {
            reject(error);
        })
    })
}
//fetch calls for users
export const fetchFixturesForUsers = async () => {
    const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/user/getFixtures', {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
        credentials: 'include'
    });
    if (response.status == 200) {
        const res = await response.json();
        console.log(res.data);
        return res.data;
    }
    else if (response.status === 429) {
        throw new Error("Limit Exceeded");
    } else {
        throw new Error("Something went wrong")
    }
}

export const getFixturesForTeam = async (teamName) => {
    const response = await fetch(process.env.REACT_APP_ENV_URL + `/api/user/getFixtureRecord/${teamName}`, {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
        credentials: "include"
    });

    if (response.status == 200) {
        const res = await response.json();
        return res.data;
    }
    else if (response.status === 429) {
        throw new Error("Limit Exceeded");
    } else {
        throw new Error("Something went wrong")
    }
}

export const getFAQs = async () => {
    const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/user/getFAQs', {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
        credentials: 'include'
    });
    if (response.status == 200) {
        const res = await response.json();
        return res.data;
    } else if (response.status === 429) {
        throw new Error("Limit Exceeded");
    } else {
        throw new Error("Something went wrong")
    }
}