import { React, useEffect, useState } from 'react'
import { Navbar } from '../../components/Navbar/navbar';
import { Footer } from '../../components/Footer/footer';
import { useAuthContext } from '../../context/authContext'
import './reviewInfo.css'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const ReviewInfo = () => {

    const { firstName, lastName, email, contactNumber, teamName, tournamentId, venueId, divisionId, players, managerFirstName, managerLastName, managerEmail, managerContactNumber, password, confirmPassword } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const isMobileScreen = window.innerWidth <= 1000 ? true : false

    useEffect(() => {
        // Dynamically add Razorpay script
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        script.onload = () => {
            console.log('Razorpay script loaded');
        };
        script.onerror = () => {
            toast.error('something went wrong...please register again');
        };
        document.body.appendChild(script);
        if (email.length === 0 || contactNumber.length === 0 || teamName.length === 0) {
            navigate('/registerteam');
            return;
        }
        return () => {
            // Cleanup script on component unmount
            document.body.removeChild(script);
        };
    }, []);

    const handlePayment = async () => {

        setLoading(true);
        const res = await fetch(process.env.REACT_APP_ENV_URL + '/api/payment/createOrder', {
            method: 'GET',
            headers: { "Content-type": "application/json" },
            credentials: 'include'
        })
        if (res.status == 200) {
            let data = await res.json();
            console.log(data);
            let order_id = data.data.id;
            let amount = data.data.amount;
            let options = {
                "key": process.env.RP_KEY_ID,
                "amount": amount,
                "currency": "INR",
                "name": "Registration Fee",
                "description": "",
                "order_id": order_id,
                "handler": function (response) {
                    verifyPayment(response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature);
                },
            };
            let razorPayObject = new window.Razorpay(options);
            razorPayObject.on('payment.failed', function (response) {
                toast.error('Payment Failed, if money deducted will be refunded');
            });
            razorPayObject.open();
        } else {
            toast.error("Something went wrong")//to be replaced with toast notification
            console.log(res);
        }
    }

    const verifyPayment = async (razorpay_order_id, razorpay_payment_id, razorpay_signature) => {
        const body = {
            order_id: razorpay_order_id,
            payment_id: razorpay_payment_id,
            payment_signature: razorpay_signature
        }
        const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/payment/verifypayment', {
            method: 'POST',
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(body),
            credentials: 'include'
        })
        console.log(response);
        if (response.status === 200) {
            toast.success("Payment successfull");
            handleRegister(razorpay_order_id, razorpay_payment_id);
        } else {
            toast.error("Payment failed, if money was deducted will be refunded within 7-8 days");
        }
    };

    const handleRegister = async (rp_order_id, rp_payment_id) => {
        const body = {
            team_name: teamName,
            first_name: firstName,
            last_name: lastName,
            email_id: email,
            contact_number: contactNumber,
            password: password,
            confirm_password: confirmPassword,
            division_id: divisionId.value,
            venue_id: venueId.value,
            player_names: players,
            manager_name: `${managerFirstName} ${managerLastName}`,
            manager_email: managerEmail,
            manager_contact_number: managerContactNumber,
            tournament_id: tournamentId.value,
            rp_order_id: rp_order_id,
            rp_payment_id: rp_payment_id
        }
        try {
            const res = await fetch(process.env.REACT_APP_ENV_URL + '/api/user/registerteam', {
                method: 'POST',
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(body),
                credentials: 'include'
            })
            if (res.status == 200) {
                toast.success("Team registered Successfully");
                setLoading(false);
                navigate(`/registerteam/confirmation/${rp_order_id + ' ' + rp_payment_id}`);
            }
            else {
                const response = await res.json();
                setLoading(false);
                toast.error(response.error);
            }
        } catch (error) {
            console.log('Code came in catch block ', error.message);
            //replace with notification section
        }
    }

    return (
        <div>
            <Navbar />
            <div style={{ width: '100%', minheight: '100vh', padding: '5%', paddingTop: isMobileScreen ? '10%' : '5%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h1 style={{ marginBottom: isMobileScreen ? 30 : 50, fontSize: isMobileScreen ? '28px' : '' }}>Review</h1>
                <div className='info_wrapper'>
                    <div>
                        <p>Name</p>
                        <p>{firstName} {lastName}</p>
                    </div>
                    <div>
                        <p>Email</p>
                        <p>{email}</p>
                    </div>
                    <div>
                        <p>Contact</p>
                        <p>{contactNumber}</p>
                    </div>
                </div>
                <div className='info_wrapper'>
                    <div>
                        <p>Team Name</p>
                        <p>{teamName}</p>
                    </div>
                    <div>
                        <p>Tournament</p>
                        <p>{tournamentId != null ? tournamentId.label : ''}</p>
                    </div>
                    <div>
                        <p>Division</p>
                        <p>{divisionId != null ? divisionId.label : ''}</p>
                    </div>
                    <div>
                        <p>Venue</p>
                        <p>{venueId != null ? (isMobileScreen ? (venueId.label).substring(0, venueId.label.length - 7) + '...' : venueId.label) : ''}</p>
                    </div>

                </div>
                <div className='info_wrapper'>
                    <div>
                        <p>Team captain</p>
                        <p>{managerFirstName}</p>
                    </div>
                    <div>
                        <p>Contact number</p>
                        <p>{managerContactNumber}</p>
                    </div>
                </div>
                <div className='info_wrapper'>
                    <div>
                        <p>Registration fee</p>
                        <p style={{ letterSpacing: '0.1rem' }}>₹2,000</p>
                    </div>
                </div>
                <button className='primaryButton' onClick={handlePayment}>
                    {!loading ?
                        'Register'
                        :
                        <ClipLoader color='#fff' size={18} speedMultiplier={0.7} />
                    }
                </button>
                <p style={{ fontSize: '0.7rem', marginTop: isMobileScreen ? '1.5rem' : '1rem', fontFamily: 'CustomFontLight', width: '90%', textAlign: isMobileScreen ? 'left' : 'center' }}>By registering I accept the rules of the tournament and Terms and conditions of FlexFooty.</p>
            </div>
            <Footer />
        </div>
    )
}
